var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8" }, [
        _c(
          "div",
          {},
          [
            _c("vue-dropzone", {
              ref: "myVueDropzone",
              attrs: { id: "dropzone", options: _vm.dropzoneOptions },
              on: {
                "vdropzone-success": _vm.dSuccess,
                "vdropzone-error": _vm.dError,
                "vdropzone-queue-complete": _vm.vCompleted,
                "vdropzone-sending": _vm.vSending
              }
            }),
            _vm._v(" "),
            _vm.sending
              ? _c("p", { staticClass: "text-center mt-2 mb-2" }, [
                  _vm._v("Cargando archivos...")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "alert alert-info mt-2" }, [
              _vm.maxsize > 0
                ? _c("p", [
                    _vm._v(
                      "El peso máximo permitido por imagen no puede superar los " +
                        _vm._s(_vm.maxFileSize) +
                        "MB."
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.maxfiles > 0
                ? _c("p", [
                    _vm._v(
                      "El límite de imágenes permitidas es " +
                        _vm._s(_vm.maxfiles) +
                        "."
                    )
                  ])
                : _vm._e()
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c(
            "table",
            { staticClass: "table table-striped" },
            _vm._l(_vm.images, function(image) {
              return _c("tr", [
                _c("td", [
                  _c("img", {
                    staticStyle: { "max-width": "15%" },
                    attrs: { src: image.url, alt: "" }
                  })
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.deleteItem(image)
                        }
                      }
                    },
                    [_vm._v("Eliminar")]
                  )
                ])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _vm.images.length > 0
          ? _c("div", { staticClass: "text-center mt-3" }, [
              _c(
                "a",
                { staticClass: "btn btn-success", attrs: { href: _vm.edit } },
                [_vm._v("Configuración de fotos 360")]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="">
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="dSuccess"
      @vdropzone-error="dError" @vdropzone-queue-complete="vCompleted" @vdropzone-sending="vSending"></vue-dropzone>
                    <p v-if="sending" class="text-center mt-2 mb-2">Cargando archivos...</p>
                    <div class="alert alert-info mt-2">
                        <p>¡Para garantizar la correcta visualización del inmueble, recomendamos publicar imágenes en formato horizontal, tamaño mínimo 1200px de ancho.</p>

                        <p>Las 5 primeras fotos de la lista, aparecerán publicadas en el PDF que genera la plataforma para envío a los clientes.</p>

                        <p v-if="maxsize > 0">El peso máximo permitido por imagen no puede superar los {{ maxFileSize }}MB.</p>

                        <p v-if="maxfiles > 0">El límite de imágenes permitidas es {{ maxfiles }}.</p>
                    </div>
                </div>
                
                <div class="card">
                    <table class="table table-striped">
                        <draggable v-model="images" tag="tbody" :component-data="getComponentData()">
                            <tr v-for="(image, index) in images">
                                <td style="vertical-align: middle;"><i class="fa fa-arrows"></i></td>
                                <td style="vertical-align: middle">{{ getIndex(index) }}. </td>
                                <td style="vertical-align: middle;"><img :src="image.url" alt="" style="max-width: 15%;"> 
                                <span v-if="index == 0" class="ml-4">Imagen principal</span></td>
                                <td style="vertical-align: middle;"><a v-on:click.stop.prevent="deleteItem(image)" class="btn btn-danger">Eliminar</a></td>
                            </tr>
                        </draggable>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            action: '',
            fetchurl: '',
            updateurl: '',
            deleteurl: '',
            csrf: '',
            maxsize: {
                type: Number,
            },
            maxfiles: {
                type: Number,
            },
        },
        data() {
            return {
                images: [],
                sending: false,
                dropzoneOptions: {
                    url: this.action,
                    thumbnailWidth: 150,
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> Arrastre las fotos o haga click aquí",
                    headers: {
                        'X-CSRF-TOKEN': this.csrf
                    },
                },
            }
        },
        methods: {
            handleSort() {
                let values = {
                    sorted: {},
                };
                for(let i = 0; i < this.images.length; i++) {
                    values['sorted'][this.images[i].id] = i;
                }

                axios.post(this.updateurl, values).then((response) => {
                    this.$toastr.s("Cambios guardados");
                });
            },
            getComponentData() {
                return {
                    on: {
                        sort: this.handleSort
                    },
                }
            },
            updateImageList() {
                axios.get(this.fetchurl, {}).then((response) => {
                    this.images = response.data.data;
                });
            },
            deleteItem(item) {
                if(confirm('¿Está seguro que desea eliminar este elemento?')) {
                    axios.post(this.deleteurl, {
                        'media': item.id,
                        '_method': 'delete',
                    }).then((response) => {
                        this.updateImageList();
                    });
                }
            },
            dSuccess(file, response) {
                this.$refs.myVueDropzone.removeFile(file);

                this.updateImageList();
            },
            dError(file, message, xhr) {
                if(message.errors != undefined, message.errors.file.length > 0) {
                    this.$toastr.e(message.errors.file.join(", "));
                }
                else {
                    this.$toastr.e(message.message);
                }
            },
            vSending(file, xhr, formData) {
                this.sending = true;
            },
            vCompleted(file, xhr, formData) {
                this.sending = false;
            },
            getIndex(index) {
                return index + 1;
            }
        },
        mounted() {
            this.updateImageList();
        },
        computed: {
            maxFileSize() {
                return (this.maxsize / 1024);
            }
        },
        components: {
            'vue-dropzone': require('vue2-dropzone'),
            'draggable': require('vuedraggable')
        }
    }
</script>
<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="">
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="dSuccess"
      @vdropzone-error="dError" @vdropzone-queue-complete="vCompleted" @vdropzone-sending="vSending"></vue-dropzone>
                    <p v-if="sending" class="text-center mt-2 mb-2">Cargando archivos...</p>
                    <div class="alert alert-info mt-2">
                        <p v-if="maxsize > 0">El peso máximo permitido por imagen no puede superar los {{ maxFileSize }}MB.</p>

                        <p v-if="maxfiles > 0">El límite de imágenes permitidas es {{ maxfiles }}.</p>
                    </div>
                </div>
                
                <div class="card">
                    <table class="table table-striped">
                        <tr v-for="image in images">
                            <td><img :src="image.url" alt="" style="max-width: 15%;"></td>
                            <td><a v-on:click.stop.prevent="deleteItem(image)" class="btn btn-danger">Eliminar</a></td>
                        </tr>
                    </table>
                </div>

                <div v-if="images.length > 0" class="text-center mt-3">
                    <a :href="edit" class="btn btn-success">Configuración de fotos 360</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            action: '',
            fetchurl: '',
            deleteurl: '',
            edit: '',
            csrf: '',
            maxsize: {
                type: Number,
            },
            maxfiles: {
                type: Number,
            },
        },
        data() {
            return {
                images: [],
                sending: false,
                dropzoneOptions: {
                    url: this.action,
                    thumbnailWidth: 150,
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> Arrastre las fotos o haga click aquí",
                    headers: {
                        'X-CSRF-TOKEN': this.csrf
                    },
                },
            }
        },
        methods: {
            updateImageList() {
                axios.get(this.fetchurl, {}).then((response) => {
                    this.images = response.data.data;
                });
            },
            deleteItem(item) {
                if(confirm('¿Está seguro que desea eliminar este elemento?')) {
                    axios.post(this.deleteurl, {
                        'media': item.id,
                        '_method': 'delete',
                    }).then((response) => {
                        this.updateImageList();
                    });
                }
            },
            dSuccess(file, response) {
                this.$refs.myVueDropzone.removeFile(file);

                this.updateImageList();
            },
            dError(file, message, xhr) {
                if(message.errors != undefined, message.errors.file.length > 0) {
                    this.$toastr.e(message.errors.file.join(", "));
                }
                else {
                    this.$toastr.e(message.message);
                }
            },
            vSending(file, xhr, formData) {
                this.sending = true;
            },
            vCompleted(file, xhr, formData) {
                this.sending = false;
            }
        },
        mounted() {
            this.updateImageList();
        },
        computed: {
            maxFileSize() {
                return (this.maxsize / 1024);
            }
        },
        components: {
            'vue-dropzone': require('vue2-dropzone'),
        }
    }
</script>